import * as React from 'react';
import { useStrapiStore } from '../../store/useStrapiStore';
import {
  ApiResponseLayout,
  BlogSlides,
  Layout,
  BlogArticles,
} from '../../components';
import { BlogData } from '../../utilities/types';

const Blog = () => {
  const { blogState, blogData } = useStrapiStore((state) => ({
    blogState: state.blogItemsState,
    blogData: state.blogData as { data: BlogData[] },
  }));

  if (blogState === 'loading')
    return <ApiResponseLayout apiResponse="loading" />;

  if (!blogData || !blogData.data || blogState === 'error')
    return <ApiResponseLayout apiResponse="error" />;

  const blogSlides = blogData.data.slice(18, 21);

  return (
    <Layout
      title="Irorun | Your easy access to urgent loans"
      description="Need cash urgently? Get up to N50,000 quickly from Irorun to meet that urgent need, with low interest rates and no hassle."
    >
      <div className="container blog">
        <BlogSlides slides={blogSlides} />
      </div>
      <div
        className="border"
        style={{
          marginTop: '3rem',
          marginBottom: '4rem',
        }}
      ></div>
      <div className="container blog">
        <BlogArticles articles={blogData.data} />
      </div>
      <div
        className="border"
        style={{
          marginTop: '3rem',
          marginBottom: '2rem',
        }}
      ></div>
    </Layout>
  );
};

export default Blog;
